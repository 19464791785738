import { useState } from 'react'
import { CardMedia, Modal, Typography, Box } from '@mui/material'
import Slider from 'react-slick'
import { Characteristic } from '@glass/models'
import { QuestionCard } from './QuestionCard'

const QuestionsPopup = ({
  questions,
  onChange,
  onClose,
  onCancelSelected,
  questionType,
}: {
  questions: Characteristic[]
  onChange: (value: Characteristic[]) => void
  onClose: () => void
  onCancelSelected: () => void
  questionType?: string
}) => {
  let sliderRef: Slider | null = null
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const handleChangeIndex = (index: number) => {
    setCurrentIndex(index)
  }

  const allAnswered =
    questions?.length > 0
      ? questions?.every((question) => question.answer_yes || question.answer_no || question.answer_not_know)
      : true

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index: number) => {
      handleChangeIndex(index)
    },
  }

  const handleChangeCharacteristic = (value: Characteristic) => {
    onChange(
      questions.map((item) => {
        if (item.id === value.id) {
          return value
        }
        return item
      }),
    )
    if (currentIndex < questions.length - 1) sliderRef?.slickGoTo(currentIndex + 1)
  }

  return (
    <Modal open={true} disableAutoFocus={true}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          height: 'auto',
          backgroundColor: '#EEF4F8',
          borderRadius: '28px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          maxWidth: '760px',
          p: 6,
        }}
      >
        <Box>
          {allAnswered && (
            <Box height={224} display='flex' alignItems='center' justifyContent='center' flexDirection='column' gap={4}>
              {/* <CardMedia
                component='img'
                sx={{ width: 72, height: 72, cursor: 'pointer' }}
                image={process.env.PUBLIC_URL + '/img/thumb-up.svg'}
              /> */}

              {/* <Button sx={{ width: 300 }} variant='outlined' type='button' onClick={onClose}>
                Select another glass or continue filling up the rest of the inquiry
              </Button>
              <Button
                sx={{ width: 300 }}
                variant='contained'
                type='button'
                onClick={() => {
                  onCancelSelected()
                }}
              >
                Cancel
              </Button> */}
              <Typography fontWeight={'700'} color={'#14151F'}>
                {questionType} selected 👍
              </Typography>
              <p>Select another glass or continue filling up the rest of the inquiry</p>
              <Box display='flex' alignItems='center' justifyContent='center' gap={4} width={300}>
                <button className='btn-raised' onClick={onClose} style={{ width: '100%' }}>
                  <label style={{ cursor: 'pointer' }}>OK</label>
                </button>
                <button className='btn-stroked' style={{ width: '100%' }} onClick={onCancelSelected}>
                  <label style={{ cursor: 'pointer' }}>Cancel</label>
                </button>
              </Box>
            </Box>
          )}
          <Box sx={{ display: allAnswered ? 'none' : 'flex', alignItems: 'center', gap: 1, marginTop: 4, mb: 2 }}>
            <Box sx={{ display: 'flex', gap: 5 }} ml='auto'>
              <CardMedia
                component='img'
                sx={{ width: 24, height: 24, cursor: 'pointer' }}
                image={process.env.PUBLIC_URL + '/img/chevron-left-gray.svg'}
                onClick={() => {
                  sliderRef?.slickPrev()
                }}
              />
              <Box display='flex' gap={3}>
                {questions.map((question, index) => (
                  <Typography
                    fontWeight={index === currentIndex ? '700' : '300'}
                    key={question.id}
                    color={index === currentIndex ? '#14151F' : '#6A6B71'}
                  >
                    {index + 1}
                  </Typography>
                ))}
              </Box>

              <CardMedia
                component='img'
                sx={{ width: 24, height: 24, cursor: 'pointer' }}
                image={process.env.PUBLIC_URL + '/img/chevron-right-gray.svg'}
                onClick={() => {
                  sliderRef?.slickNext()
                }}
              />
            </Box>
          </Box>

          <Box sx={{ mt: 2 }} display={allAnswered ? 'none' : 'initial'}>
            <Box
              sx={{
                position: 'relative',
                m: -2,
              }}
            >
              <Slider ref={(slider) => (sliderRef = slider)} {...sliderSettings}>
                {questions.map((item, index) => (
                  <Box key={item.id + index}>
                    <Box sx={{ m: 2 }}>
                      <QuestionCard
                        characteristic={item}
                        onChange={handleChangeCharacteristic}
                        disabled={item?.force_yes}
                      />
                    </Box>
                  </Box>
                ))}
              </Slider>
              <button className='btn-stroked' style={{ width: '100%' }} onClick={onCancelSelected}>
                <label style={{ cursor: 'pointer' }}>Cancel</label>
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default QuestionsPopup
