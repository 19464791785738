import './quote.css'
import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { trackPromise } from 'react-promise-tracker'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import expand from '@glass/assets/icons/expand.png'
import flag from '@glass/assets/icons/uk-flag.png'
import up from '@glass/assets/icons/up.png'
import { Questions } from '@glass/components/WindowSelector/Questions'
import { REACT_APP_API_DOMAIN_URL } from '@glass/envs'
import { getQuoteService } from '@glass/services/apis/get-quote.service'
import { formatAddress } from '@glass/utils/format-address/format-address.util'
import { formatLicenseNumber } from '@glass/utils/index'
import { Characteristic, Quote } from '../../core/models/quote.model'

export type QuotePagePreviewBEProps = {
  quoteCount?: boolean
}

export const QuotePagePreviewBE: React.FC<QuotePagePreviewBEProps> = ({ quoteCount = true }) => {
  const { id } = useParams()
  const [quoteDetails, setQuoteDetails] = useState<Quote | undefined>(undefined)
  const [quoteInfoOpen, setInfoOpen] = useState<boolean>(true)

  const [tempLicenseNum, setTempLicense] = useState('')
  const listQuestionCharacteristics = useMemo(() => {
    const tempListQuestion: Characteristic[] = []
    if (quoteDetails?.inquiry_characteristics) {
      Object.keys(quoteDetails?.inquiry_characteristics)?.forEach((key) => {
        quoteDetails?.inquiry_characteristics[key]?.forEach((characteristic) => {
          tempListQuestion?.push({ ...characteristic })
        })
      })
    }
    return tempListQuestion
  }, [quoteDetails?.inquiry_characteristics])

  const getQuote = () => {
    if (id) {
      trackPromise(
        getQuoteService(id, quoteCount).then((res) => {
          if (res.success) {
            setQuoteDetails(res.data)
          } else {
            toast(res.message)
          }
        }),
      )
    }
  }

  useEffect(() => {
    // Get Quote Data
    if (id) {
      getQuote()
    }

    // scroll to top on page load
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (quoteDetails) {
      setTempLicense(formatLicenseNumber(quoteDetails.registration_number))
    }
  }, [quoteDetails])

  return (
    <div className='my-4 my-md-5'>
      <div className='center'>
        <div className='pt-4 pb-4'>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <a
              className='transaction-name'
              href={`${REACT_APP_API_DOMAIN_URL}${quoteDetails?.be_url}`}
              rel='noopener noreferrer'
              target='_self'
            >
              {`${REACT_APP_API_DOMAIN_URL}${quoteDetails?.be_url}`}
            </a>
          </div>
        </div>
        {quoteInfoOpen && (
          <div className='quote-info-main'>
            <div className='client-info-container'>
              <div className='info-container'>
                <div id='scroll-to-top'>
                  <div className='yellow-box' key={tempLicenseNum}>
                    <div className='blue-box'>
                      <img className='flag' src={flag} alt='' />
                      <div className='gb'>UK</div>
                    </div>
                    <input
                      className='license-input'
                      type='text'
                      defaultValue={tempLicenseNum}
                      placeholder='ENTER REG'
                      disabled={!!quoteDetails?.registration_number}
                    />
                  </div>
                  <div className='client-info'>
                    {quoteDetails?.customer_f_name} {quoteDetails?.customer_s_name}
                  </div>
                  <div className='client-info'>{formatAddress(quoteDetails?.invoice_address)}</div>
                  <div className='client-info'>{quoteDetails?.customer_email}</div>
                  <div className='client-info'>{quoteDetails?.customer_phone}</div>
                  <div className='client-info'>{quoteDetails?.make}</div>
                  <div className='client-info'>{quoteDetails?.model}</div>
                </div>
              </div>
            </div>
            <div className='quote-info-bottom'>
              <div>
                <div className='compact-bottom-row'>
                  <span className='quote-selected-windows'>
                    <b>Selected windows:</b>{' '}
                  </span>
                  {!!quoteDetails &&
                    quoteDetails.glass_location.map((element) => (
                      <span key={element} className='client-windows'>
                        {element.charAt(0).toUpperCase() + element.slice(1)}
                      </span>
                    ))}
                </div>
                {listQuestionCharacteristics?.length > 0 && (
                  <Box sx={{ marginTop: { xs: 6, lg: 4 }, maxWidth: '420px', marginX: { xs: 0, lg: 4 } }}>
                    <Box
                      border={{ xs: '1px solid #D8D8DA', lg: 'unset' }}
                      borderRadius={{ xs: '6px', lg: 0 }}
                      p={{ xs: '12px 8px 8px 8px', lg: 0 }}
                      position='relative'
                      boxShadow={{
                        xs: '0px 4px 17px 0px rgba(147, 147, 147, 0.04), 0px 2px 12px 0px rgba(147, 147, 147, 0.07), 0px 1px 7px 0px rgba(147, 147, 147, 0.09)',
                        lg: 'unset',
                      }}
                      bgcolor={{ xs: '#F9FAFA', lg: 'unset' }}
                    >
                      <Questions characteristics={listQuestionCharacteristics} disabled onChange={() => {}} />
                    </Box>
                  </Box>
                )}
              </div>

              <img
                onClick={() => setInfoOpen(false)}
                src={up}
                alt=''
                style={{ width: '17px' }}
                className='client-up-icon'
              />
            </div>
          </div>
        )}
        {!quoteInfoOpen && (
          <div className='quote-info-compact'>
            <div className='client-info-compact'>
              <div className='yellow-box' key={tempLicenseNum}>
                <div className='blue-box'>
                  <img className='flag' src={flag} alt='' />
                  <div className='gb'>UK</div>
                </div>
                <input className='license-input' type='text' defaultValue={tempLicenseNum} placeholder='ENTER REG' />
              </div>
              <div className='client-info'>
                {quoteDetails?.customer_f_name} {quoteDetails?.customer_s_name}
              </div>
              <div className='compact-bottom-row'>
                {!!quoteDetails &&
                  quoteDetails.glass_location.map((element) => (
                    <span key={element} className='client-windows'>
                      {element}
                    </span>
                  ))}
              </div>
              {listQuestionCharacteristics?.length > 0 && (
                <Box sx={{ marginTop: { xs: 2, lg: 2 }, maxWidth: '420px', marginX: { xs: 0, lg: 4 } }}>
                  <Box
                    border={{ xs: '1px solid #D8D8DA', lg: 'unset' }}
                    borderRadius={{ xs: '6px', lg: 0 }}
                    p={{ xs: '12px 8px 8px 8px', lg: 0 }}
                    position='relative'
                    boxShadow={{
                      xs: '0px 4px 17px 0px rgba(147, 147, 147, 0.04), 0px 2px 12px 0px rgba(147, 147, 147, 0.07), 0px 1px 7px 0px rgba(147, 147, 147, 0.09)',
                      lg: 'unset',
                    }}
                    bgcolor={{ xs: '#F9FAFA', lg: 'unset' }}
                  >
                    <Questions characteristics={listQuestionCharacteristics} disabled onChange={() => {}} />
                  </Box>
                </Box>
              )}
            </div>
            <img onClick={() => setInfoOpen(true)} className='client-info-icon' src={expand} alt='' />
          </div>
        )}
      </div>
    </div>
  )
}
