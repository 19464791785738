import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/system'
import { trackPromise } from 'react-promise-tracker'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AddressInput } from '@glass/components/AddressInput/AddressInput'
import { ChangeAddress } from '@glass/components/ChangeAddress'
import { LicensePlate } from '@glass/components/LicensePlate'
import { WindowSelector } from '@glass/components/WindowSelector'
import { AddressType, CarType, OrderState } from '@glass/enums'
import { REACT_APP_API_DOMAIN_URL } from '@glass/envs'
import { useRetrieveVehData } from '@glass/hooks/useRetrieveVehData'
import { Address, Characteristic, CustomerInfo, QuoteDto, VehicleData } from '@glass/models'
import { checkInquiryService } from '@glass/services/apis/check-inquiry.service'
import { createQuoteBEService } from '@glass/services/apis/create-quote-be.service'
import { getCustomerInfoService } from '@glass/services/apis/get-customer-info.service'
import { getQuoteService } from '@glass/services/apis/get-quote.service'
import { getVehicleService } from '@glass/services/apis/get-vehicle.service'
import { formatLicenseNumber } from '@glass/utils/format-license-number/format-license-number.util'
import { clearRequestedURL, getRequestedURL } from '@glass/utils/session/session.util'
import { QuoteBEDialog } from '../Customer/QuoteDialogBE'

export type CustomerFormBEProps = {
  editMode?: boolean
}

export const CustomerFormBE: React.FC<CustomerFormBEProps> = ({ editMode = false }) => {
  const navigate = useNavigate()
  const { licenseNum, quoteId } = useParams()

  const [searchParams] = useSearchParams()
  const customerId = searchParams.get('customer_id')
  const userId = searchParams.get('user_id')

  const [licenseSearchVal, setLicense] = useState(licenseNum || '')
  const [vehData, setVehData] = useState<VehicleData | undefined>()
  const [billingAddress, setBillingAddress] = useState<Address | undefined>(undefined)
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo | undefined>(undefined)

  // keep track if request can be submitted
  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const phoneRef = useRef<HTMLInputElement>(null)

  // const [comment, setComment] = useState<string>('')
  // const [attachments, setAttachments] = useState<Attachment[]>([])

  // for determining which form is not filled
  const [incorrectFormIndex, setIncorrectFormIndex] = useState(99)

  const [onSubmitMessage, setOnSubmitMessage] = useState('')

  // temporary things for car selection menu - Rainer
  const [selectedCarType, setSelectedCarType] = useState<CarType>(CarType.THREE_DOOR)

  // for getting the array of broken windows
  const [selectedBrokenWindows, setSelectedBrokenWindows] = useState<string[]>([])

  // for getting the array of broken windows
  const [inquiryCharacteristics, setInquiryCharacteristics] = useState<Characteristic[]>([])

  const [isReloadPage, setIsReloadPage] = useState<boolean>(false)

  const [hasError, setHasError] = useState<boolean>(false)
  const [invalid, setInvalid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showQuoteActive, setShowQuoteActive] = useState<string>('')
  const [showQuoteLostPopup, setShowQuoteLostPopup] = useState<string>('')

  const brokenWindowsToCustomer = (windows: string[]) => {
    setSelectedBrokenWindows(windows)
  }

  const inquiryCharacteristicsToCustomer = (data: Characteristic[]) => {
    setInquiryCharacteristics(data)
  }

  const getCustomerInfo = () => {
    if (customerId) {
      trackPromise(
        getCustomerInfoService(Number(customerId)).then((res) => {
          if (res.success) {
            setCustomerInfo(res.data?.[0])
          }
        }),
      )
    }
  }

  // functions for checking if necessary fields are filled and enable submit request
  const checkIfFilled = (value: string | undefined, errorMsg: string, formIndex: number) => {
    if (!value) {
      setOnSubmitMessage(errorMsg)
      setIncorrectFormIndex(formIndex)
      return true
    }
    return false
  }

  // check if any windows are selected before submit request
  function checkIfSelected() {
    if (selectedBrokenWindows.length === 0) {
      setOnSubmitMessage('Select windows that need replacing')
      return true
    }
    return false
  }

  const retrieveVehData = (data: CarType) => {
    setSelectedCarType(data)
  }

  useRetrieveVehData(vehData, retrieveVehData)

  function handleSubmitClick() {
    const firstNameNotFilled = checkIfFilled(firstNameRef?.current?.value, 'First name not filled', 0)
    const lastNameNotFilled = checkIfFilled(lastNameRef?.current?.value, 'Last name not filled', 1)
    const emailNotFilled = checkIfFilled(emailRef?.current?.value, 'Email not filled', 2)
    const phoneNotFilled = checkIfFilled(phoneRef?.current?.value, 'Phone number not filled', 3)
    const billingNotFilled = checkIfFilled(billingAddress?.postcode, 'Postal code not filled', 4)
    const licenseNumNotFilled = checkIfFilled(licenseSearchVal, 'License number not filled', 5)
    const windowNotFilled = checkIfSelected()

    // enable submit request if all form fields are filled (more conditions can be added)
    if (
      firstNameNotFilled ||
      lastNameNotFilled ||
      emailNotFilled ||
      phoneNotFilled ||
      billingNotFilled ||
      windowNotFilled ||
      licenseNumNotFilled
    ) {
      return
    }

    // post data
    const firstName = (firstNameRef?.current?.value || '').trim()
    const lastName = (lastNameRef?.current?.value || '').trim()
    // const fullName = `${firstName} ${lastName}`

    const postData: QuoteDto = {
      // customer_name: fullName,
      customer_f_name: firstName,
      customer_s_name: lastName,
      customer_phone: (phoneRef?.current?.value || '').trim(),
      customer_email: (emailRef?.current?.value || '').trim(),
      customer_address: {
        postcode: billingAddress?.postcode || '',
        latitude: billingAddress?.latitude || '',
        longitude: billingAddress?.longitude || '',
        line_1: billingAddress?.line_1 || '',
        line_2: billingAddress?.line_2 || '',
        line_3: billingAddress?.line_3 || '',
        line_4: billingAddress?.line_4 || '',
        locality: billingAddress?.locality || '',
        town_or_city: billingAddress?.town_or_city || '',
        county: billingAddress?.county || '',
        district: billingAddress?.district || '',
        country: billingAddress?.country || '',
      },
      registration_number: licenseSearchVal,
      glass_location: (selectedBrokenWindows || []).map((item) => item.toLowerCase()),
      inquiry_characteristics: inquiryCharacteristics,
      customer_id: Number(customerId),
      user_id: Number(userId),
    }

    if (getRequestedURL()) {
      postData.fe_requested_url = getRequestedURL()
    }
    trackPromise(
      createQuoteBEService(postData).then((res) => {
        if (res.success) {
          navigate('/quote/be/preview/' + res.data.fe_token)
          clearRequestedURL()
        } else {
          toast(res.message)
        }
      }),
    )
  }

  const fetchVehData = async (license: string | undefined, hasInputChange = false) => {
    if (!!license) {
      const resCheckInquiry = await trackPromise(
        (async () => {
          const res = await checkInquiryService({ registration_number: license })
          return res
        })(),
      )
      if ((!!quoteId && hasInputChange) || !quoteId) {
        if (resCheckInquiry.success && resCheckInquiry?.data?.result?.fe_token) {
          const orderState = resCheckInquiry.data.result?.order_state
          const fe_token = resCheckInquiry.data.result.fe_token
          if ((editMode && licenseSearchVal !== license) || !editMode) {
            if (orderState === OrderState.LOST) {
              setShowQuoteLostPopup(fe_token)
            } else {
              setShowQuoteActive(fe_token)
            }
            localStorage.removeItem('currentInquiry')
            return
          }
        }
      }
      // fetch vehicle data
      trackPromise(
        getVehicleService(license)
          .then((res) => {
            if (res.success && res.data?.Model) {
              setVehData(res.data)
              setHasError(false)
              setInvalid(false)
              setIsLoading(false)
              // compare licenseNum and license
              // if diff -> reload page
              if (licenseNum?.replace(' ', '') !== license?.replace(' ', '')) {
                setIsReloadPage(true)
              }
            } else {
              setInvalid(true)
              setIsLoading(false)
            }
          })
          .catch(() => {
            setHasError(true)
            setIsLoading(false)
          }),
      )
    }
  }

  // const handleChangeFiles = (files: Attachment[]) => {
  //   setAttachments(files)
  // }

  const handleVehInputChange = (data: string | undefined) => {
    fetchVehData(data, true)
    setLicense(formatLicenseNumber(data))
  }

  const handleNavigateQuote = (fe_token: string) => {
    trackPromise(
      getQuoteService(fe_token, false).then((res) => {
        if (res.success) {
          window.open(`${REACT_APP_API_DOMAIN_URL}${res.data?.be_url}`, '_target', 'noopener,noreferrer')
        } else {
          toast(res.message)
        }
      }),
    )
  }

  const handleCloseDialogQuote = () => {
    setLicense('')
    setShowQuoteLostPopup('')
    setShowQuoteActive('')
  }

  useEffect(() => {
    fetchVehData(licenseNum)
  }, [licenseNum])

  useEffect(() => {
    if (customerId) {
      getCustomerInfo()
    }
  }, [customerId])

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.value = customerInfo?.customer_f_name || ''
    }
    if (lastNameRef.current) {
      lastNameRef.current.value = customerInfo?.customer_s_name || ''
    }
    if (emailRef.current) {
      emailRef.current.value = customerInfo?.customer_email || ''
    }
    if (phoneRef.current) {
      phoneRef.current.value = customerInfo?.customer_phone || ''
    }
  }, [customerInfo])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <section className='sec-customer'>
        <div className='container'>
          <div className='tab-content'>
            <div className='row' id='scroll-to-top'>
              <LicensePlate
                placeholderVal={'ENTER REG'}
                licenseNumber={licenseSearchVal}
                model={!!vehData ? vehData.Make + ' ' + vehData.Model : 'Make & Model'}
                handleVehInputChange={handleVehInputChange}
                hasError={hasError}
                invalid={invalid}
                isLoading={isLoading}
                hideEditButton
                autoFocus={!licenseSearchVal}
              />
              <br />
            </div>
            <div className='row text-center'>
              <div className='col-md-9 mx-auto'>
                <div>
                  <div id='scroll-focus'>
                    <h2 className='text-blue  text-24'>Select Broken Glasses</h2>
                    <p>Tap directly or select below</p>
                  </div>

                  <div className='parent'>
                    {/* car image display */}
                    <WindowSelector
                      carType={selectedCarType}
                      setCarType={setSelectedCarType}
                      brokenWindowsToCustomer={brokenWindowsToCustomer}
                      brokenWindowsToComponent={[]}
                      registrationNumber={licenseSearchVal?.replace(' ', '')}
                      onChangeCharacteristics={inquiryCharacteristicsToCustomer}
                      isReloadPage={isReloadPage}
                      setIsReloadPage={setIsReloadPage}
                    />
                    {/* <br />
                    <br />
                    <p className='fs-18 text-blue'>Your comments (optional)</p> */}

                    {/* {comments.map((item, index) => (
                      <div key={index} className='text-left'>
                        <p>
                          <strong>Comment {index + 1}: </strong>
                          {item.comment}
                        </p>
                        <AddPictures disabled={true} attachments={item.attachments} />
                      </div>
                    ))} */}

                    {/* <div className='form-group mb-4'>
                      <textarea
                        name=''
                        id=''
                        rows={4}
                        className='form-control h-auto'
                        placeholder='Details for glass or any other comment.'
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>
                    </div> */}

                    {/* <AddPictures attachments={attachments} onChangeFiles={handleChangeFiles} /> */}
                    {/* <small className='d-block mt-2'>*Recommended</small> */}
                    <form className='my-md-5 my-4'>
                      <p className='fs-18 text-blue'>Fill your personal details</p>
                      <br />
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group mb-4'>
                            <div className='h6 text-left text-black-50'>First name</div>
                            <input
                              ref={firstNameRef}
                              type='text'
                              className={incorrectFormIndex === 0 ? 'form-control form-not-filled' : 'form-control'}
                              placeholder='First name'
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group mb-4'>
                            <div className='h6 text-left text-black-50'>Last name</div>
                            <input
                              ref={lastNameRef}
                              type='text'
                              className={incorrectFormIndex === 1 ? 'form-control form-not-filled' : 'form-control'}
                              placeholder='Last name'
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group mb-4'>
                            <div className='h6 text-left text-black-50'>Email</div>
                            <input
                              ref={emailRef}
                              type='text'
                              className={incorrectFormIndex === 2 ? 'form-control form-not-filled' : 'form-control'}
                              placeholder='Email'
                            />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group mb-4'>
                            <div className='h6 text-left text-black-50'>Phone</div>
                            <input
                              ref={phoneRef}
                              type='text'
                              className={incorrectFormIndex === 3 ? 'form-control form-not-filled' : 'form-control'}
                              placeholder='Phone'
                              disabled
                            />
                          </div>
                        </div>
                        <div className='col-md-12'>
                          <div className='form-group mb-4'>
                            <div className='d-flex justify-content-between'>
                              <div className='h6 text-left text-black-50'>Postcode</div>
                              {!!customerId && (
                                <ChangeAddress
                                  qid={''}
                                  customerId={Number(customerId)}
                                  addressType={AddressType.DELIVERY}
                                  initialAddress={{
                                    address_id: Number(customerInfo?.address_id) || -1,
                                    address_type: customerInfo?.address_type || AddressType.CONTACT,
                                    line_1: customerInfo?.line_1 || '',
                                    line_2: customerInfo?.line_2 || '',
                                    postcode: customerInfo?.postcode?.toString() || '',
                                    latitude: customerInfo?.latitude?.toString() || '',
                                    longitude: customerInfo?.longitude?.toString() || '',
                                    town_or_city: customerInfo?.town_or_city || '',
                                    county: customerInfo?.county || '',
                                    country: customerInfo?.country || '',
                                    formatted_address: [],
                                  }}
                                  onChangeAddress={(event) => {
                                    setBillingAddress(event)
                                  }}
                                  isFormBE
                                />
                              )}
                            </div>
                            <AddressInput
                              address={billingAddress}
                              formError={incorrectFormIndex === 4}
                              onChange={setBillingAddress}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    {/* submit request button */}
                    <div className='row'>
                      <div className='col-md-7 mx-auto'>
                        <div className='submit-request-msg'>{onSubmitMessage}</div>
                        <button className='btn btn-purple-radius w-100 mb-3' onClick={handleSubmitClick} id='submitBtn'>
                          {editMode ? 'Save Quote' : 'Submit request'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {(!!showQuoteActive || !!showQuoteLostPopup) && (
        <QuoteBEDialog
          onConfirm={() => handleNavigateQuote(showQuoteActive || showQuoteLostPopup)}
          licenseNum={licenseNum}
          isQuoteActive={!!showQuoteActive}
          onClose={handleCloseDialogQuote}
        />
      )}
    </Box>
  )
}
